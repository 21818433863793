.form-visible {
    display: block;
}

.form-hidden {
    display: none;
}

.bd-bg-color {
    background-color: rgb(2, 34, 2);
}
.bd-bg-img{
    /* background-image: url('racket.png'); */
    background-image: linear-gradient(to left, rgb(2, 34, 2) 50%, transparent 100%), 
                      url('racket.png');
    background-size: auto 65%; /* Make the image fit the height of the div */
    background-position: right center; /* Position the image on the right */
    background-repeat: no-repeat; 
    background-blend-mode: multiply;
    /* filter: blur(20px); */
    /* z-index: 1; */
}
.bd-bg-gradient-down{
    /* background: rgb(2, 34, 2); */
    background: linear-gradient(180deg, rgb(209, 17, 17) 5%, rgb(2, 34, 2) 100%);
}
.bd-bg-gradient-up{
    /* background: rgb(2, 34, 2); */
    background: linear-gradient(0deg, rgb(209, 17, 17) 5%, rgb(2, 34, 2) 100%);
}
.red-button {
    background-color: rgb(209, 17, 17);
    border: none; /* Optional: Remove default button border */
    padding: 0.5rem 1rem; /* Optional: Adjust padding */
    border-radius: 4px; /* Optional: Add rounded corners */
    cursor: pointer; /* Optional: Change cursor to pointer */
  }
  
  .red-button:hover {
    background-color: rgb(109, 2, 2); /* Optional: Darker red on hover */
  }
  